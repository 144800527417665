<template>
    <v-container fluid class="grey lighten-5">
        <v-row class="mx-5" v-if="the_form">
            <v-col cols=12>
                <q-form-entries-data-table :title="the_form.FormTitle +' Data'" :slug="$route.params.form_slug"></q-form-entries-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'
import QFormEntriesDataTable from '@/FML/data/components/QFormEntriesDataTable';

export default {
    props: [],
    data: () => ({
        loaded: false,
        the_form: null
    }),
    mounted: function() {
        this.loadForm(this.$route.params.form_slug)
    },
    computed: {

    },
    methods: {
        loadForm: function(slug) {
            var g = this
            g.loaded = false
            g.$set(g, "the_form", null);
            QuilityAPI.getFormWithSlug(slug)
                .then(function(data) {
                    g.$set(g, "the_form", data);
                    g.$nextTick(function() { g.loaded = true })
                })
                .catch(function(err) {
                    g.showError("Whoops! Can't find that form!")
                });
            g.hasChanges = false;
        },
    },
    watch: {
        '$route.params.form_slug': function(newV) {
            if (typeof newV == 'undefined' || newV == null) {
                return
            }
            this.loadForm(newV)
        }
    },
    components: {
        QFormEntriesDataTable
    },
}
</script>
<style scoped>
</style>